// localStorage.js

// Function to set user details in localStorage
export const setUserDetails = (userData) => {
    localStorage.setItem('userId', userData.userId);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('name', userData.name);
    localStorage.setItem('type', userData.type);
};

// Function to get user details from localStorage
export const getUserDetails = () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('name');
    const type = localStorage.getItem('type');
    return { userId, token, name, type };
};

// Function to clear user details from localStorage (logout)
export const clearUserDetails = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('type');
};
