// ErrorPage.js
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { clearUserDetails } from '../../localStorage';

const ErrorPage = ({errorType}) => {
  const navigate = useNavigate()

  const handleBack = () => {
    clearUserDetails();
    navigate("/login");
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    card: {
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      textAlign: 'center',
      maxWidth: '400px',
    },
    heading: {
      color: '#d9534f',
      marginBottom: '10px',
    },
    message: {
      color: '#555',
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#007bff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  if (errorType === "location"){
    return (
        <div style={styles.container}>
          <div style={styles.card}>
            <h3 style={styles.heading}>Error: Location Not Enabled!</h3>
            <p style={styles.message}>
              Please enable location services on your device and scan the QR code again. Thanks.
            </p>
          </div>
        </div>
      );
  } else if(errorType === "radius") {
    return (
        <div style={styles.container}>
          <div style={styles.card}>
            <h3 style={styles.heading}>Error: Not in allowed radius!</h3>
            <p style={styles.message}>
              You are too far away from allowed radius of QR code. Please get in 5Kms of radius and scan the QR code again. Thanks.
            </p>
          </div>
        </div>
      );
  } else {
    return (
      <div style={styles.container}>
        <div style={styles.card}>
          <h3 style={styles.heading}>Error: Access Denied!</h3>
          <p style={styles.message}>
            You are not allowed to access this app.
          </p>
          <button style={styles.button} onClick={handleBack}>
          Back
        </button>
        </div>
      </div>
    );
  }


};

export default ErrorPage;
