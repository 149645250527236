// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import FeedbackForm from "./FeedbackForm";
// import { getDepartmentsByPlantId } from "../../Services/apiServices";

// const FeedbackWrapper = () => {
//   const [locationEnabled, setLocationEnabled] = useState(true);
//   const [userLocation, setUserLocation] = useState(null);
//   const [orgIdAndPlantId, setOrgIdAndPlantId] = useState(null);
//   const [departmentList, setDepartmentList] = useState([]);
//   const [orgId, setOrgId] = useState(null)
//   const [plantId, setPlantId] = useState(null)
//   const navigate = useNavigate();
//   const params = useParams();
 

//   useEffect(() => {
//     if (params.orgIdAndPlantId) {
//       setOrgIdAndPlantId(params.orgIdAndPlantId);
//     }
//   }, [params.orgIdAndPlantId]);

//   useEffect(() => {
//     if (!orgIdAndPlantId || typeof orgIdAndPlantId !== "string") {
//       return;
//     }

//     const decodedOrgIdAndPlantId = decodeBase64(orgIdAndPlantId);
//     const [orgId, plantId, lat, long] = decodedOrgIdAndPlantId.split("-");
//     setOrgId(orgId);
//     setPlantId(plantId);

//     getDepartmentsByPlantId(
//       plantId,
//       //"43c8735b3478dc9f9545d0e217589dbc" //prod
//       "2ef3b1f7489d4c36bbbf6494a0fc1045",//dev
//       "plantadmin"
//     )
//       .then((res) => {
//         const departments = res.data.map((dept) => ({
//           name: dept.department_name,
//           ref_id: dept.department_ref_id,
//         }));
//         setDepartmentList(departments);
//       })
//       .catch((error) => {
//         console.error("Error fetching departments:", error);
//       });

//     const checkDistanceAndNavigate = (userCoords) => {
//       const radius = 5; // Radius in kilometers
//       const distance = calculateDistance(userCoords.latitude, userCoords.longitude, parseFloat(lat), parseFloat(long));

//       if (distance <= radius) {
//         navigate(`/feedback/${orgIdAndPlantId}`);
//       } else {
//         navigate("/radius-error");
//       }
//     };

//     const askForLocationPermission = () => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             const { latitude, longitude } = position.coords;
//             const userCoords = { latitude, longitude };
//             setUserLocation(userCoords);
//             checkDistanceAndNavigate(userCoords);
//           },
//           (error) => {
//             console.error("Error getting location:", error.message);
//             setLocationEnabled(false);
//             navigate("/error");
//           }
//         );
//       } else {
//         console.error("Geolocation is not supported by this browser.");
//         setLocationEnabled(false);
//         navigate("/error");
//       }
//     };

//     askForLocationPermission();
//   }, [orgIdAndPlantId, navigate]);

//   const decodeBase64 = (base64String) => {
//     try {
//       return decodeURIComponent(
//         atob(base64String)
//           .split("")
//           .map(
//             (char) => "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2)
//           )
//           .join("")
//       );
//     } catch (e) {
//       console.error("Error decoding base64 string", e);
//       return null;
//     }
//   };

//   const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 6371; // Radius of the Earth in kilometers
//     const dLat = deg2rad(lat2 - lat1);
//     const dLon = deg2rad(lon2 - lon1);
//     const a =
//       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//       Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
//       Math.sin(dLon / 2) * Math.sin(dLon / 2);
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     return R * c; // Distance in kilometers
//   };

//   const deg2rad = (deg) => {
//     return deg * (Math.PI / 180);
//   };

//   if (!locationEnabled) {
//     return null;
//   }

//   return <FeedbackForm departmentList={departmentList} orgId={orgId} plantId={plantId} orgIdAndPlantId={orgIdAndPlantId} />;
// };

// export default FeedbackWrapper;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FeedbackForm from "./FeedbackForm";
import { getDepartmentsByPlantId, getEmployeeList } from "../../Services/apiServices";

const FeedbackWrapper = () => {
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [orgIdAndPlantId, setOrgIdAndPlantId] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [plantId, setPlantId] = useState(null);
  const [empList, setEmpList] = useState([])
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.orgIdAndPlantId) {
      setOrgIdAndPlantId(params.orgIdAndPlantId);
    }
  }, [params.orgIdAndPlantId]);

  useEffect(() => {
    if (!orgIdAndPlantId || typeof orgIdAndPlantId !== "string") {
      return;
    }

    const decodedOrgIdAndPlantId = decodeBase64(orgIdAndPlantId);
    const [orgId, plantId, lat, long] = decodedOrgIdAndPlantId.split("-");
    setOrgId(orgId);
    setPlantId(plantId);

    getEmployeeList(
      orgId,
      plantId,
      "2b7e453d7056d74fd65ac34c6caa44a0", // dabur org admin
      // "e18669df28cc81f2ca4596f823aa87bb", // prod
      // "f3312e1f18ff62c75a14a560ec9d2327",// dev
      "orgadmin"
    ).then(res => {
      setEmpList(res.data);
    })

  }, [orgIdAndPlantId, navigate]);

  console.log(empList)

  useEffect(() => {
    if (!orgIdAndPlantId || typeof orgIdAndPlantId !== "string") {
      return;
    }

    const decodedOrgIdAndPlantId = decodeBase64(orgIdAndPlantId);
    const [orgId, plantId, lat, long] = decodedOrgIdAndPlantId.split("-");
    setOrgId(orgId);
    setPlantId(plantId);

    getDepartmentsByPlantId(
      plantId,
      "be4977adadc0df0f21496d5c3c9ad85e", // dabur kco plant admin
      // "43c8735b3478dc9f9545d0e217589dbc", // prod
      // "2ef3b1f7489d4c36bbbf6494a0fc1045",// dev
      "plantadmin"
    )
      .then((res) => {
        const departments = res.data.map((dept) => ({
          name: dept.department_name,
          ref_id: dept.department_ref_id,
        }));
        setDepartmentList(departments);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });

    const checkDistanceAndNavigate = (userCoords) => {
      const radius = 5; // Radius in kilometers
      const distance = calculateDistance(userCoords.latitude, userCoords.longitude, parseFloat(lat), parseFloat(long));

      if (distance <= radius) {
        navigate(`/feedback/${orgIdAndPlantId}`);
      } else {
        navigate("/radius-error");
      }
    };

    const askForLocationPermission = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const userCoords = { latitude, longitude };
            setUserLocation(userCoords);
            checkDistanceAndNavigate(userCoords);
          },
          (error) => {
            console.error("Error getting location:", error.message);
            setLocationEnabled(false);
            navigate("/error");
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLocationEnabled(false);
        navigate("/error");
      }
    };

    askForLocationPermission();
  }, [orgIdAndPlantId, navigate]);

  const decodeBase64 = (base64String) => {
    try {
      return decodeURIComponent(
        atob(base64String)
          .split("")
          .map(
            (char) => "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2)
          )
          .join("")
      );
    } catch (e) {
      console.error("Error decoding base64 string", e);
      return null;
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  if (!locationEnabled) {
    return null;
  }

  return <FeedbackForm departmentList={departmentList} orgId={orgId} plantId={plantId} orgIdAndPlantId={orgIdAndPlantId} empList={empList}/>;
};

export default FeedbackWrapper;

