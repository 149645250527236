// import React, { useEffect, useState } from "react";
// import Navbar from "../NavBar/NavBar";
// import Sidebar from "../Sidebar/Sidebar.js";
// import Dashboard from "../Dashboard/Dashboard";
// import FeedbackTable from "../FeedbackTable/FeedbackTable";
// import "./Home.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { getUserDetails } from "../../localStorage.js";
// import { getEmployeeDetials } from "../../Services/apiServices.js";
// import {ToastContainer, toast} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';

// const Home = () => {
//   const [currentView, setCurrentView] = useState("dashboard");
//   const [loading, setLoading] = useState(false);
//   const [userDetails, setUserDetails] = useState({});
//   const [employeeDetails, setEmployeeDetails] = useState([])

//   useEffect(() => {
//     const userDetials = getUserDetails();
//     setUserDetails(userDetials);
//   }, []);

//   useEffect(() => {
//     if (userDetails.token) {
//       setLoading(true);
//       getEmployeeDetials(userDetails.token, userDetails.type).then(res => {
//         setEmployeeDetails(res.data)
//         setLoading(false);
//       })
//     }
//   }, [userDetails.token, setLoading]);

//   const handleSidebarItemClick = (view) => {
//     setCurrentView(view);
//   };

//   return (
//     <div className="home">
//       {/* <Navbar  employeeDetails={employeeDetails} /> */}
//       <div className="content">
//         {loading && (
//           <div className="loader-overlay">
//             <div className="spinner-border text-primary" role="status">
//               <span className="visually-hidden">Loading...</span>
//             </div>
//           </div>
//         )}
//         <Sidebar
//           onSidebarItemClick={handleSidebarItemClick}
//           currentView={currentView}
//         />
//         <div className="main-content">
//           <>
//           <Navbar  employeeDetails={employeeDetails} />
//           {currentView === "dashboard" && <Dashboard setLoading={setLoading} employeeDetails={employeeDetails} />}
//           {currentView === "feedback" && employeeDetails[0] && (
//             <FeedbackTable setLoading={setLoading} employeeDetails={employeeDetails} />
//           )}
//           </>

//           {/* <div className="app-footer">
//             Powered by <a style={{color:"#ffffff", textDecoration:"none"}} href="https://www.momentumindia.in" target="_blank">Momentum</a> ©
//             </div> */}
//         </div>
//       </div>
//       <ToastContainer autoClose="1500"/>
//     </div>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import Navbar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar.js";
import Dashboard from "../Dashboard/Dashboard";
import FeedbackTable from "../FeedbackTable/FeedbackTable";
import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUserDetails } from "../../localStorage.js";
import { getEmployeeDetials } from "../../Services/apiServices.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [currentView, setCurrentView] = useState("dashboard");
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const userDetials = getUserDetails();
    setUserDetails(userDetials);
  }, []);

  useEffect(() => {
    if (userDetails.token) {
      fetchEmpDetials();
    }
  }, [userDetails.token, setLoading]);

  const fetchEmpDetials = () => {
    setLoading(true);
    getEmployeeDetials(userDetails.token, userDetails.type).then((res) => {
      if (res.data) {
        setEmployeeDetails(res.data);
        setLoading(false);
      }
    });
  };

  const handleSidebarItemClick = (view) => {
    setCurrentView(view);
    setSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="home">
      <div className="content">
        {loading && (
          <div className="loader-overlay">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <button className="sidebar-toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
        <Sidebar
          onSidebarItemClick={handleSidebarItemClick}
          currentView={currentView}
          sidebarOpen={sidebarOpen}
        />
        <div className={`main-content ${sidebarOpen ? "sidebar-open" : ""}`}>
          <>
            <Navbar
              employeeDetails={employeeDetails}
              fetchEmpDetials={fetchEmpDetials}
            />
            {currentView === "dashboard" && (
              <Dashboard
                setLoading={setLoading}
                employeeDetails={employeeDetails}
              />
            )}
            {currentView === "feedback" && employeeDetails[0] && (
              <FeedbackTable
                setLoading={setLoading}
                employeeDetails={employeeDetails}
              />
            )}
          </>
        </div>
      </div>
      <ToastContainer autoClose="1500" />
    </div>
  );
};

export default Home;
