// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar.css */

.navbar {
    padding: 10px;
    display: flex;
    justify-content: space-between; /* Align items to left and right */
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-nav .nav-link {
    color: white !important;
    margin-right: 15px;
  }
  
  .navbar-nav .nav-link:hover {
    color: #ccc !important;
  }
  
  .nav-link svg {
    margin-right: 5px;
  }
  
  .navbar-toggler {
    display: none; /* Hide the collapsible icon */
  }
  
  .custom-dropdown {
    /* width: fit-content; */
  }
  
  .custom-dropdown .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    /* transform: translateX(-85%); */
  }

  @media (max-width: 768px) {
    .navbar {
      justify-content: space-around;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/NavBar/Navbar.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,aAAa;IACb,aAAa;IACb,8BAA8B,EAAE,kCAAkC;EACpE;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa,EAAE,8BAA8B;EAC/C;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,qBAAqB;IACrB,mBAAmB;IACnB,iCAAiC;EACnC;;EAEA;IACE;MACE,6BAA6B;IAC/B;EACF","sourcesContent":["/* Navbar.css */\r\n\r\n.navbar {\r\n    padding: 10px;\r\n    display: flex;\r\n    justify-content: space-between; /* Align items to left and right */\r\n  }\r\n  \r\n  .navbar-brand {\r\n    font-size: 1.5rem;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .navbar-nav .nav-link {\r\n    color: white !important;\r\n    margin-right: 15px;\r\n  }\r\n  \r\n  .navbar-nav .nav-link:hover {\r\n    color: #ccc !important;\r\n  }\r\n  \r\n  .nav-link svg {\r\n    margin-right: 5px;\r\n  }\r\n  \r\n  .navbar-toggler {\r\n    display: none; /* Hide the collapsible icon */\r\n  }\r\n  \r\n  .custom-dropdown {\r\n    /* width: fit-content; */\r\n  }\r\n  \r\n  .custom-dropdown .dropdown-menu {\r\n    left: auto !important;\r\n    right: 0 !important;\r\n    /* transform: translateX(-85%); */\r\n  }\r\n\r\n  @media (max-width: 768px) {\r\n    .navbar {\r\n      justify-content: space-around;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
