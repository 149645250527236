// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upm-text-center {
  text-align: center;
}

.upm-mb-3 {
  margin-bottom: 1rem;
}

.upm-profile-icon {
  margin-bottom: 1rem;
  color: #000000; /* Change color as needed */
}

.upm-modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.upm-modal-body {
  padding: 2rem;
}

.upm-form-label {
  font-weight: bold;
  text-align: left;
  width: 100%;
}

.upm-form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
  cursor: not-allowed;
}

.upm-modal-footer {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserProfileModal/UserProfileModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,cAAc,EAAE,2BAA2B;AAC7C;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".upm-text-center {\r\n  text-align: center;\r\n}\r\n\r\n.upm-mb-3 {\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.upm-profile-icon {\r\n  margin-bottom: 1rem;\r\n  color: #000000; /* Change color as needed */\r\n}\r\n\r\n.upm-modal-title {\r\n  font-size: 1.5rem;\r\n  font-weight: bold;\r\n}\r\n\r\n.upm-modal-body {\r\n  padding: 2rem;\r\n}\r\n\r\n.upm-form-label {\r\n  font-weight: bold;\r\n  text-align: left;\r\n  width: 100%;\r\n}\r\n\r\n.upm-form-control:disabled {\r\n  background-color: #e9ecef;\r\n  opacity: 1;\r\n  cursor: not-allowed;\r\n}\r\n\r\n.upm-modal-footer {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
