// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import LoginForm from "./Components/LoginForm/LoginForm";
// import FeedbackWrapper from "./Components/FeedbackForm/FeedbackWrapper";
// import Home from "./Components/Home/Home";
// import { getUserDetails } from "./localStorage";
// import ErrorPage from "./Components/ErrorPage/ErrorPage";
// import QRCodeGenerator from "./Components/QRCodeGenerator/QRCodeGenerator";

// // ProtectedRoute component to protect routes that require authentication and redirect based on user type
// const ProtectedRoute = ({ element }) => {
//   const { token, type } = getUserDetails();

//   if (!token) {
//     return <Navigate to="/login" />;
//   }

//   if (type === "superadmin" || type === "1") {
//     return <Navigate to="/access-error" />;
//   }

//   return element;
// };

// // RedirectRoute component to handle initial redirection based on token and user type
// const RedirectRoute = () => {
//   const { token, type } = getUserDetails();

//   if (!token) {
//     return <Navigate to="/login" />;
//   }

//   if (type === "superadmin" || type === "1") {
//     return <Navigate to="/access-error" />;
//   }

//   return <Navigate to="/home" />;
// };

// // PublicRoute component to prevent authenticated users from accessing certain routes
// const PublicRoute = ({ element }) => {
//   const { token } = getUserDetails();

//   return token ? <Navigate to="/home" /> : element;
// };

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<RedirectRoute />} />
//         <Route path="/login" element={<PublicRoute element={<LoginForm />} />} />
//         <Route path="/feedback/:orgIdAndPlantId" element={<FeedbackWrapper />} />
//         <Route path="/error" element={<ErrorPage errorType="location" />} />
//         <Route path="/radius-error" element={<ErrorPage errorType="radius" />} />
//         <Route path="/access-error" element={<ErrorPage errorType="access" />} />
//         <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
//         <Route path="*" element={<Navigate to="/" />} />
//         <Route path="/qr-code" element={<QRCodeGenerator/>} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginForm from "./Components/LoginForm/LoginForm";
import FeedbackWrapper from "./Components/FeedbackForm/FeedbackWrapper";
import Home from "./Components/Home/Home";
import { getUserDetails } from "./localStorage";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import QRCodeGenerator from "./Components/QRCodeGenerator/QRCodeGenerator";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";

// ProtectedRoute component to protect routes that require authentication and redirect based on user type
const ProtectedRoute = ({ element }) => {
  const { token, type } = getUserDetails();

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (type === "superadmin") {
    return <Navigate to="/qr-code" />;
  }

  if (type === "1") {
    return <Navigate to="/access-error" />;
  }

  return element;
};

// RedirectRoute component to handle initial redirection based on token and user type
const RedirectRoute = () => {
  const { token, type } = getUserDetails();

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (type === "superadmin" || type === "1") {
    return <Navigate to="/qr-code" />;
  }

  return <Navigate to="/home" />;
};

// PublicRoute component to prevent authenticated users from accessing certain routes
const PublicRoute = ({ element }) => {
  const { token } = getUserDetails();

  return token ? <Navigate to="/home" /> : element;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectRoute />} />
        <Route path="/login" element={<PublicRoute element={<LoginForm />} />} />
        <Route path="/feedback/:orgIdAndPlantId" element={<FeedbackWrapper />} />
        <Route path="/error" element={<ErrorPage errorType="location" />} />
        <Route path="/radius-error" element={<ErrorPage errorType="radius" />} />
        <Route path="/access-error" element={<ErrorPage errorType="access" />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/qr-code" element={<QRCodeGenerator />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </Router>
  );
};

export default App;
