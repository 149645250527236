import axios from "axios";
import { config } from "../config";

const localPath = config.localPath;
const devPath = config.devPath;

const getAccessTypeText = (typeNumber) => {
  switch (typeNumber) {
    case "1":
      return "user";
    case "2":
      return "plantadmin";
    case "3":
      return "orgadmin";
    case "4":
      return "plantsadmin";
    case "5":
      return 5;
    default:
      return "";
  }
};

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${devPath}/users/login`, {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const saveFeedback = async (formData) => {
  try {
    const response = await axios.post(
      `${localPath}/users/save-feedback`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error during login:", error.message);
    throw error;
  }
};

export const saveUserDetails = async (name, email, contact, feedbackId) => {
  const formData = {
    name: name,
    email: email,
    contact: contact,
    feedbackId: feedbackId,
  };
  try {
    const response = await axios.post(
      `${localPath}/users/save-user-detials`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error during saving:", error.message);
    throw error;
  }
};

export const getDepartmentsByPlantId = async (
  plantRefID,
  accessToken,
  accessType
) => {
  const payload = {
    plantRefID: plantRefID,
  };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": accessType,
    },
  };

  try {
    const response = await axios.post(
      `${devPath}/emp/dept/byplant`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching departments:", error.message);
    throw error;
  }
};

export const getCategoryByPlant = async (
  orgId,
  plantRefID,
  accessToken,
  accessType
) => {
  const payload = {
    orgId: orgId,
    plant_ref_id: plantRefID,
  };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": accessType,
    },
  };

  try {
    const response = await axios.post(
      `${devPath}/org/org-categories`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error.message);
    throw error;
  }
};

export const uploadAudioBlob = async (audioBlob) => {
  console.log(audioBlob);
  const formData = new FormData();
  formData.append("audioBlob", audioBlob);
  console.log(formData);
  try {
    const response = await axios.post(
      `${localPath}/users/upload/audio`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during file upload:", error.message);
    throw error;
  }
};

export const getAllFeedbacks = async (orgId, accessToken, accessType) => {
  const type = getAccessTypeText(accessType);

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };

  try {
    const response = await axios.get(
      `${localPath}/observation4/all-feedbacks/${orgId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error.message);
    throw error;
  }
};

export const updateFeedbackStatus = async (
  id,
  status,
  inProgressComment,
  closingComment,
  accessToken,
  accessType
) => {
  const type = getAccessTypeText(accessType);

  const data = {
    id: id,
    status: status,
    in_progress_comment: inProgressComment,
    closing_comment: closingComment,
  };
  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(
      `${localPath}/observation4/update-feedback-status`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error during saving:", error.message);
    throw error;
  }
};

export const SendFeedbackOutcomeMail = async (
  userName,
  userEmail,
  closingComment,
  accessToken,
  accessType
) => {
  const type = getAccessTypeText(accessType);

  const data = {
    userName: userName ? userName : null,
    userEmail,
    closingComment,
  };
  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(
      `${localPath}/observation4/feedback-outcome-mail`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.log("Error sending mail", error.message);
    // throw error;
  }
};

export const SendFeedbackSubmissionMail = async (
  deptHeadDetails,
  message,
  category,
  department,
  brief_idea,
  impact_on_business,
  name,
  email,
  contact
) => {
  const data = {
    deptHeadDetails,
    message,
    category,
    department,
    brief_idea,
    impact_on_business,
    name,
    email,
    contact
  };

  try {
    const response = await axios.post(
      `${localPath}/users/feedback-submission-mail`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.log("Error sending mail", error.message);
    // throw error;
  }
};

export const SendFeedbackSubmissionMailToUser = async (
  userName, userEmail
) => {
  const data = {
    userName,
    userEmail
  };

  try {
    const response = await axios.post(
      `${localPath}/users/feedback-submission-mail-to-user`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.log("Error sending mail", error.message);
    // throw error;
  }
};

export const getEmployeeDetials = async (accessToken, accessType) => {
  const type = getAccessTypeText(accessType);

  const data = {};

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(
      `${devPath}/emp/emp-details`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.log("Error getting detials", error.message);
    // throw error;
  }
};

export const getFeedbacksByDept = async (
  deptId,
  orgId,
  plantId,
  accessToken,
  accessType
) => {
  const type = getAccessTypeText(accessType);

  const data = { deptId: deptId, orgId: orgId, plantId: plantId };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(
      `${localPath}/observation4/feedbacks-by-dept`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching feedbacks", error.message);
    throw error;
  }
};

export const deleteFeedback = async (feedbackId, accessToken, accessType) => {
  const type = getAccessTypeText(accessType);

  // const data = {feedbackId:feedbackId}

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.delete(
      `${localPath}/observation4/delete-feedback/${feedbackId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting feedback", error.message);
    throw error;
  }
};

export const feedbackDashboard = async (
  orgId,
  plantId,
  deptId,
  category,
  startDate, 
  endDate,
  accessToken,
  accessType
) => {

  const type = getAccessTypeText(accessType);

  const data = { orgId, plantId, deptId, category, startDate, endDate };

  console.log(data)

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(
      `${localPath}/observation4/feedback-dashboard`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching feedbacks", error.message);
    throw error;
  }
};

export const getOrgList = async (accessToken, accessType) => {
  const type = accessType;

  const data = {
    searchText: "",
    sortby: "",
    page: 0,
  };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(`${devPath}/org/list`, data, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching orgs", error.message);
    throw error;
  }
};

export const getPlantList = async (orgId, accessToken, accessType) => {
  const type = accessType;

  const data = {
    searchText: "",
    sortby: "",
    page: 0,
    whereData: [
      {
        field: "`plant`.`organization_ref_id`",
        value: orgId,
        type: "AND",
        nested: "",
      },
    ],
  };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(`${devPath}/plant/list`, data, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching plants", error.message);
    throw error;
  }
};

export const updateEmployeeDetials = async (
  name,
  email,
  accessToken,
  accessType
) => {
  const type = getAccessTypeText(accessType);

  const data = {
    name,
    email,
    type,
  };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(
      `${devPath}/emp/profile/save`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile", error.message);
    throw error;
  }
};

export const getEmployeeList = async (
  orgId,
  plantId,
  accessToken,
  accessType
) => {
  const type = accessType;

  const data = {
    searchText: "",
    sortby: "id",
    sorttype: "desc",
    page: 0,
    whereData: [
      {
        field: "`employee`.`organization_ref_id`",
        value: orgId,
        type: "AND",
        nested: "",
      },
    ],
  };

  const config = {
    headers: {
      "x-access-token": accessToken,
      "x-access-type": type,
    },
  };
  try {
    const response = await axios.post(`${devPath}/emp/list`, data, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching employee list", error.message);
    throw error;
  }
};

export const resetPassword = async (email) => {

  const data = {
    email: email,
  };

  try {
    const response = await axios.post(`${devPath}/users/forgot-password`, data);
    return response.data;
  } catch (error) {
    console.error("Error resetting password", error.message);
    throw error;
  }
};
