// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* QRCodeGenerator.css */
.react-select-container {
    width: 100%; /* Adjust width as needed */
    margin-bottom: 10px; /* Add padding/margins as needed */
  }
  
  .qr-input {
    width: 100%; /* Adjust width as needed */
    padding: 8px; /* Add padding/margins as needed */
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .qr-container {
    display: flex;
    justify-content: center;
    margin: 7%;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    margin-top: 1%;
  }
  
  .qr-generator {
    max-width: 500px; /* Adjust max-width as needed */
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.334);
  }
  
  .qr-code-container {
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/QRCodeGenerator/QRCodeGenerator.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,WAAW,EAAE,2BAA2B;IACxC,mBAAmB,EAAE,kCAAkC;EACzD;;EAEA;IACE,WAAW,EAAE,2BAA2B;IACxC,YAAY,EAAE,kCAAkC;IAChD,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,mBAAmB;IACnB,sBAAsB;IACtB,QAAQ;IACR,cAAc;EAChB;;EAEA;IACE,gBAAgB,EAAE,+BAA+B;IACjD,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["/* QRCodeGenerator.css */\r\n.react-select-container {\r\n    width: 100%; /* Adjust width as needed */\r\n    margin-bottom: 10px; /* Add padding/margins as needed */\r\n  }\r\n  \r\n  .qr-input {\r\n    width: 100%; /* Adjust width as needed */\r\n    padding: 8px; /* Add padding/margins as needed */\r\n    margin-bottom: 10px;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .qr-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin: 7%;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n    margin-top: 1%;\r\n  }\r\n  \r\n  .qr-generator {\r\n    max-width: 500px; /* Adjust max-width as needed */\r\n    width: 100%;\r\n    padding: 20px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n    background: rgba(255, 255, 255, 0.334);\r\n  }\r\n  \r\n  .qr-code-container {\r\n    text-align: center;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
