import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.css"; // Import CSS file for Dashboard styling
import { Container, Form, Row, Col, Card, Button } from "react-bootstrap";
import CountUp from "react-countup";
import {
  feedbackDashboard,
  getPlantList,
  getDepartmentsByPlantId,
  getEmployeeList,
} from "../../Services/apiServices";
import { getUserDetails } from "../../localStorage";
import Chart from "react-apexcharts"; // Import React-ApexCharts
import Select from "react-select";
import moment from "moment"; // Import moment for date processing
import { DateRangePicker } from "react-date-range"; // Import DateRangePicker
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import { BiCalendar } from "react-icons/bi";
import { addDays } from "date-fns";

const Dashboard = ({ setLoading, employeeDetails }) => {

  const categoryOptions = [
    { label: "Cost Optimization", value: "Cost Optimization" },
    { label: "Sales & Marketing Promotion Campaign", value: "Sales & Marketing Promotion Campaign" },
    { label: "Innovation", value: "Innovation" },
    { label: "Digitization & Technology", value: "Digitization Technology" },
    { label: "New Product", value: "New Product" },
    { label: "Process Efficiencies/Sustainability", value: "Process Efficiencies/Sustainability" },
    { label: "Others", value: "Others" },
  ];

  const [plantId, setPlantId] = useState();
  const [deptId, setDeptId] = useState();
  const [orgId, setOrgId] = useState();
  const [category, setCategory] = useState();
  const [feedbackData, setFeedbackData] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [deptFeedbackData, setDeptFeedbackData] = useState([]); // State to store department feedback data
  const [categoryFeedbackData, setCategoryFeedbackData] = useState([]); // State to store category feedback data
  const [categoryList, setCategoryList] = useState(categoryOptions);
  const userDetails = getUserDetails();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (employeeDetails.length > 0) {
      setOrgId(employeeDetails[0].organization_ref_id);
      setPlantId(employeeDetails[0].plant_ref_id);
      // setDeptId(employeeDetails[0].department_ref_id);
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (orgId && plantId) {
      fetchData();
      fetchPlantList();
      // fetchCategoryList();
    }
  }, [
    orgId,
    userDetails.token,
    userDetails.type,
    plantId,
    deptId,
    category,
    selectedDates,
  ]);

  useEffect(() => {
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  const fetchData = async () => {
    setLoading(true);
    const token = userDetails.token;
    const type = userDetails.type;
    // const startDate = selectedDates[0].startDate
    // const endDate = selectedDates[0].endDate
    const selectedStartDate = selectedDates[0]?.startDate;
    const selectedEndDate = selectedDates[0]?.endDate;

    let startDateISO = null;
    let endDateISO = null;
    let startDate = null;
    let endDate = null;

    if (selectedStartDate && selectedEndDate) {
      startDate = new Date(selectedStartDate);
      endDate = new Date(selectedEndDate);

      // Set startDate to start of the day (00:00:00)
      startDate.setUTCHours(0, 0, 0, 0); // Use setUTCHours to ensure UTC time

      // Set endDate to end of the day (23:59:59.999)
      endDate.setUTCHours(23, 59, 59, 999); // Use setUTCHours to ensure UTC time

      startDate.setDate(startDate.getDate() + 1);

      endDate.setDate(endDate.getDate() + 1);

      // Convert to ISO strings
      startDateISO = startDate.toISOString();
      endDateISO = endDate.toISOString();
    }
    console.log(startDate, endDate);
    feedbackDashboard(
      orgId,
      plantId,
      deptId,
      category,
      startDateISO,
      endDateISO,
      token,
      type
    ).then((res) => {
      setFeedbackData(res.data);
      // Extract department feedback data and categories
      // const deptData = {};
      // const categoryData = {};
      // res.data.forEach((feedback) => {
      //   const dept = feedback.department;
      //   if (deptData[dept]) {
      //     deptData[dept]++;
      //   } else {
      //     deptData[dept] = 1;
      //   }

      //   const cat = feedback.category;
      //   if (categoryData[cat]) {
      //     categoryData[cat]++;
      //   } else {
      //     categoryData[cat] = 1;
      //   }
      // });
      // setDeptFeedbackData(deptData);
      // setCategoryFeedbackData(categoryData);

      const deptData = {};
      const categoryData = {};
      const othersData = [];

      res.data.forEach((feedback) => {
        const dept = feedback.department;
        if (deptData[dept]) {
          deptData[dept]++;
        } else {
          deptData[dept] = 1;
        }

        const cat = feedback.category;
        if (feedback.is_others === 1) {
          if (categoryData["Others"]) {
            categoryData["Others"]++;
          } else {
            categoryData["Others"] = 1;
          }
          othersData.push(feedback);
        } else {
          if (categoryData[cat]) {
            categoryData[cat]++;
          } else {
            categoryData[cat] = 1;
          }
        }
      });

      console.log(othersData);
      console.log(categoryData);

      // Set the aggregated data to state
      setDeptFeedbackData(deptData);
      setCategoryFeedbackData({ ...categoryData });

      // Extract and set unique categories
      // const uniqueCategories = Object.keys(categoryData).map((cat) => ({
      //   value: cat,
      //   label: cat,
      // }));
      // setCategoryList(uniqueCategories);
    });
    setLoading(false);
  };

  const fetchPlantList = async () => {
    try {
      const token = "45sd34567345346409456349834";
      const type = "superadmin";
      const response = await getPlantList(orgId, token, type);
      const plants = response.data.map((plant) => ({
        value: plant.id,
        label: plant.name,
      }));
      setPlantList(plants);
      fetchDeptList();
    } catch (error) {
      console.error("Failed to fetch plant list:", error);
      setPlantList([]);
    }
  };

  const fetchDeptList = async () => {
    try {
      const token = "45sd34567345346409456349834";
      const type = "superadmin";
      const response = await getDepartmentsByPlantId(plantId, token, type);
      const depts = response.data.map((dept) => ({
        value: dept.department_ref_id,
        label: dept.department_name,
      }));
      setDeptList(depts);
    } catch (error) {
      console.error("Failed to fetch dept list:", error);
      setDeptList([]);
    }
  };

  // const fetchCategoryList = async () => {
  //   const categoriesFromData = [
  //     ...new Set(feedbackData.map((feedback) => feedback.category)),
  //   ];
  //   const uniqueCategories = categoriesFromData.map((cat) => ({
  //     value: cat,
  //     label: cat,
  //   }));
  //   setCategoryList(uniqueCategories);
  // };

  const renderFeedbackCount = (title, count, color) => (
    <Card className="dashboard-card">
      <Card.Body>
        <Card.Title style={{ color, fontSize: "15px" }}>{title}</Card.Title>
        <Card.Text>
          <CountUp start={0} end={count} duration={4} />
        </Card.Text>
      </Card.Body>
    </Card>
  );

  const totalFeedbacks = feedbackData.length;
  const openFeedbacks = feedbackData.filter((e) => e.status === "Open").length;
  const inProgressFeedbacks = feedbackData.filter(
    (e) => e.status === "In Progress"
  ).length;
  const closedFeedbacks = feedbackData.filter(
    (e) => e.status === "Closed"
  ).length;

  // Calculate percentages, avoid NaN values
  const openPercentage = totalFeedbacks
    ? parseFloat(((openFeedbacks / totalFeedbacks) * 100).toFixed(2))
    : 0;
  const inProgressPercentage = totalFeedbacks
    ? parseFloat(((inProgressFeedbacks / totalFeedbacks) * 100).toFixed(2))
    : 0;
  const closedPercentage = totalFeedbacks
    ? parseFloat(((closedFeedbacks / totalFeedbacks) * 100).toFixed(2))
    : 0;

  // Prepare data for feedback per month chart
  const feedbacksPerMonth = feedbackData.reduce((acc, feedback) => {
    const month = moment(feedback.CreatedAt).format("MMM");
    if (!acc[month]) acc[month] = 0;
    acc[month]++;
    return acc;
  }, {});

  // List of months January to December
  const months = moment.monthsShort();
  const feedbackCounts = months.map((month) => feedbacksPerMonth[month] || 0);

  // Prepare data for department feedbacks as percentage
  const deptLabels = Object.keys(deptFeedbackData);
  const deptPercentages = deptLabels.map((dept) => {
    const deptCount = deptFeedbackData[dept];
    return totalFeedbacks
      ? parseFloat(((deptCount / totalFeedbacks) * 100).toFixed(2))
      : 0;
  });

  // Prepare data for department feedbacks as count
  const deptFeedbackCounts = deptLabels.map(
    (dept) => deptFeedbackData[dept] || 0
  );

  // Prepare data for category feedbacks as count
  const categoryLabels = Object.keys(categoryFeedbackData);
  const categoryFeedbackCounts = categoryLabels.map(
    (cat) => categoryFeedbackData[cat] || 0
  );

  // const deptChartOptions = {
  //   chart: {
  //     type: "donut",
  //   },
  //   dataLabels: {
  //     enabled: false,
  //     formatter: (val) => `${val.toFixed(2)}%`,
  //   },
  //   style: {
  //     fontSize: "12px", // Adjust the font size as needed
  //     colors: ["#000"], // Optional: Set the font color
  //   },
  //   labels: deptLabels,
  //   colors: [
  //     "#FF0000", // Red
  //     "#FFA500", // Orange
  //     "#008000", // Green
  //     "#4CAF50", // Light Green
  //     "#2196F3", // Blue
  //     "#9C27B0", // Purple
  //     "#FF5722", // Deep Orange
  //     "#607D8B", // Blue Grey
  //     "#FFC107", // Amber
  //     "#673AB7", // Deep Purple
  //     "#00BCD4", // Cyan
  //     "#795548", // Brown
  //     "#E91E63", // Pink
  //     "#CDDC39", // Lime
  //     "#00FF00", // Lime Green
  //     "#FFD700", // Gold
  //     "#00FFFF", // Aqua
  //     "#FF69B4", // Hot Pink
  //     "#B22222", // Firebrick
  //     "#8B008B", // Dark Magenta
  //   ],
  //   legend: {
  //     position: "bottom",
  //   },
  // };

  // const deptChartSeries = deptPercentages;

  // const deptChartOptions = {
  //   chart: {
  //     type: "bar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: true,
  //       barHeight: "50%",
  //       distributed: true, // Ensure each bar gets a unique color
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false, // Hide data labels
  //   },
  //   xaxis: {
  //     categories: deptLabels,
  //   },
  //   colors: [
  //     "#FF0000",
  //     "#FFA500",
  //     "#008000",
  //     "#4CAF50",
  //     "#2196F3",
  //     "#9C27B0",
  //     "#FF5722",
  //     "#607D8B",
  //     "#FFC107",
  //     "#673AB7",
  //     "#00BCD4",
  //     "#795548",
  //     "#E91E63",
  //     "#CDDC39",
  //     "#00FF00",
  //     "#FFD700",
  //     "#00FFFF",
  //     "#FF69B4",
  //     "#B22222",
  //     "#8B008B",
  //   ],
  //   tooltip: {
  //     enabled: true,
  //     y: {
  //       formatter: (val) => `${val.toFixed(2)}%`,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  // };

  // const deptChartSeries = [
  //   {
  //     name: "Departments",
  //     data: deptPercentages,
  //   },
  // ];

  const colors = [
    "#2E9482",
    "#1768C4",
    "#FA8072",
    "#7145A8",
    "#FFD16B",
    "#98335B",
    "#455EB2",
    "#C4424F",
    "#399FE4",
    "#E0F973",
    "#9E07ED",
    "#F40CC8",
    "#17CCC7",
    "#0840BC",
  ];

  const deptBarChartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: "50%",
        distributed: true, // Ensure each bar gets a unique color
      },
    },
    xaxis: {
      categories: deptLabels,
      labels: {
        show: false,
      },
    },
    // yaxis: {
    //   labels: {
    //     formatter: (val) => Math.floor(val), // Ensure y-axis labels are integers
    //     style: {
    //       fontSize: "9px", // Adjust the font size here as needed
    //     },
    //   },
    // },
    yaxis: {
      min: 0, // Minimum value of the y-axis
      // max: 2, // Maximum value of the y-axis
      tickAmount: 5, // Set tickAmount equal to maxDataValue for integer steps
      labels: {
        formatter: (val) => {
          if (Number.isInteger(val)) {
            return val; // Only return integer values
          }
          return '';
        },
        style: {
          fontSize: "9px", // Adjust the font size as needed
        },
      },
    },
    colors: colors,

    dataLabels: {
      enabled: false, // Hide data labels
    },
    legend: {
      show: true, // Show the legend
      position: "bottom",
      fontSize: "10px",
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => `${val}`,
      },
    },
  };

  const deptBarChartSeries = [
    {
      name: "Suggestions",
      data: deptFeedbackCounts,
    },
  ];

  // const deptBarChartOptions = {
  //   chart: {
  //     type: "bar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   xaxis: {
  //     categories: deptLabels,
  //   },
  //   colors: ["#2196F3"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  // };

  // const deptBarChartSeries = [
  //   {
  //     name: "Feedbacks",
  //     data: deptFeedbackCounts,
  //   },
  // ];

  // const truncateLabel = (label, maxLength = 10) => {
  //   return label.length > maxLength
  //     ? label.substring(0, maxLength) + "..."
  //     : label;
  // };

  // const categoryBarChartOptions = {
  //   chart: {
  //     type: "bar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   xaxis: {
  //     categories: categoryLabels.map((label) => truncateLabel(label)),
  //     tooltip: {
  //       enabled: false, // Disable the default tooltip for x-axis labels
  //     },
  //   },
  //   colors: ["#FF5722"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: function (
  //         value,
  //         { series, seriesIndex, dataPointIndex, w }
  //       ) {
  //         // Return the full category name for the tooltip
  //         return categoryLabels[dataPointIndex];
  //       },
  //     },
  //   },
  // };

  const categoryBarChartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Make bars vertical
        distributed: true, // Ensure each bar gets a unique color
      },
    },
    xaxis: {
      categories: categoryLabels, // Directly use categoryLabels
      labels: {
        show: false, // Show x-axis labels
      },
      tooltip: {
        enabled: false, // Disable the default tooltip for x-axis labels
      },
    },
    // yaxis: {
    //   labels: {
    //     formatter: (val) => Math.floor(val), // Ensure y-axis labels are integers
    //     style: {
    //       fontSize: "9px", // Adjust the font size here as needed
    //     },
    //   },
    // },
    yaxis: {
      min: 0, // Minimum value of the y-axis
      // max: 2, // Maximum value of the y-axis
      tickAmount: 5, // Set tickAmount equal to maxDataValue for integer steps
      labels: {
        formatter: (val) => {
          if (Number.isInteger(val)) {
            return val; // Only return integer values
          }
          return '';
        },
        style: {
          fontSize: "9px", // Adjust the font size as needed
        },
      },
    },
    colors: colors,

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true, // Show legend
      position: "bottom",
      fontSize: "10px",
    },
    tooltip: {
      y: {
        formatter: function (value) {
          // Return the value as is for the tooltip
          return value;
        },
      },
    },
  };

  const categoryBarChartSeries = [
    {
      name: "Suggestions",
      data: categoryFeedbackCounts,
    },
  ];

  const donutChartOptions = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)}%`,
      style: {
        fontSize: "8px", // Adjust the font size as needed
      },
    },
    labels: ["Open", "In Progress", "Closed"],
    colors: ["#FF0000", "#FFA500", "#008000"],
    legend: {
      position: "bottom",
      fontSize: "10px",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          // Ensure the tooltip shows percentage with a % sign
          return `${val.toFixed(2)}%`;
        },
      },
    },
  };

  const donutChartSeries = totalFeedbacks
    ? [openPercentage, inProgressPercentage, closedPercentage]
    : [];

  // const barChartOptions = {
  //   chart: {
  //     type: "bar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   xaxis: {
  //     categories: months,
  //   },
  //   colors: ["#008FFB"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  // };

  // const barChartSeries = [
  //   {
  //     name: "Feedbacks",
  //     data: feedbackCounts,
  //   },
  // ];

  const lineChartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: months,
    },
    // yaxis: {
    //   labels: {
    //     formatter: (val) => (val), // Ensure y-axis labels are integers
    //     style: {
    //       fontSize: "9px", // Adjust the font size as needed
    //     },
    //   },
    // },
    yaxis: {
      min: 0, // Minimum value of the y-axis
      // max: 2, // Maximum value of the y-axis
      tickAmount: 5, // Set tickAmount equal to maxDataValue for integer steps
      labels: {
        formatter: (val) => {
          if (Number.isInteger(val)) {
            return val; // Only return integer values
          }
          return '';
        },
        style: {
          fontSize: "9px", // Adjust the font size as needed
        },
      },
    },
    colors: ["#496c58"],
    dataLabels: {
      enabled: true, // Enable data labels
      formatter: (value) => value.toFixed(0), // Format the labels as needed
      style: {
        fontSize: "10px", // Adjust font size of data labels
        // colors: ["#000"], // Set color of data labels
      },
      offsetY: 0, // Adjust vertical offset if needed
      dropShadow: {
        enabled: true, // Optional: Add shadow for better visibility
        color: "#000",
        opacity: 0.7,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    stroke: {
      width: 2, // Line thickness
      curve: "straight", // Use smooth curves for better appearance
    },
    markers: {
      size: 5, // Adjust marker size if needed
    },
  };

  // const lineChartOptions = {
  //   chart: {
  //     type: "line",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   xaxis: {
  //     categories: months,
  //   },
  //   yaxis: {
  //     labels: {
  //       formatter: (val) => Math.floor(val), // Ensure y-axis labels are integers
  //       style: {
  //         fontSize: "9px", // Adjust the font size here as needed
  //       },
  //     },
  //   },
  //   colors: ["#496c58"],
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     width: 2, // Thin lines
  //     curve: "straight", // Pointed curves
  //   },
  //   markers: {
  //     size: 5, // Adjust the size of the markers as needed
  //   },
  // };

  const lineChartSeries = [
    {
      name: "Suggestions",
      data: feedbackCounts,
    },
  ];

  const statusBarChartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true, // Enables different colors for each bar
      },
    },
    xaxis: {
      categories: ["Open", "In Progress", "Closed"],
    },
    colors: ["#FF0000", "#FFA500", "#008000"],
    dataLabels: {
      enabled: false,
    },
  };

  const statusBarChartSeries = [
    {
      name: "Feedback Status",
      data: [openFeedbacks, inProgressFeedbacks, closedFeedbacks],
    },
  ];

  const handleSelectDate = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    setSelectedDates([
      { startDate: startDate, endDate: endDate, key: "selection" },
    ]);
    // setShowDatePicker(false)
  };

  const displayDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };

  const clearDateFilter = () => {
    setSelectedDates([{ startDate: null, endDate: null, key: "selection" }]);
    setShowDatePicker(false);
  };

  const clearAllFilters = () => {
    setSelectedDates([{ startDate: null, endDate: null, key: "selection" }]);
    // setPlantId();
    setDeptId();
    setCategory(null);
  };

  const isFilterActive =
    // plantId ||
    deptId ||
    category ||
    selectedDates[0].startDate ||
    selectedDates[0].endDate;

  const customStyles = {
    control: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey", // Custom color for placeholder
      fontWeight: "normal",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "normal",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "13px", // Reduced font size for dropdown menu
      fontWeight: "normal",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px", // Reduced font size for options
      backgroundColor: state.isSelected ? "#2196F3" : provided.backgroundColor, // Custom background color for selected option
      color: state.isSelected ? "white" : "black", // Custom text color for selected option
      fontWeight: "normal",
    }),
  };

  return (
    <div className="dashboard-container">
      <hr style={{ margin: "0px" }} />
      <Container className="dashboard">
        <div style={{ textAlign: "right" }}>
          {/* <div className="clear-all-filters" onClick={clearAllFilters}>
            { plantId || deptId || category || selectedDates[0].startDate || selectedDates[0].endDate ? "Clear All Filters" : "" }

            </div> */}
          <Button
            variant="outline-primary"
            onClick={clearAllFilters}
            disabled={!isFilterActive}
            className="clear-all-filters no-hover"
            style={{ color: !isFilterActive ? "grey" : "red" }}
          >
            Clear All Filters
          </Button>
          {/* <div className="dashboard-date-filter">
            <div style={{ color: "#496c58" }} onClick={displayDatePicker}>
              {selectedDates[0].startDate && selectedDates[0].endDate
                ? `${moment(selectedDates[0].startDate).format(
                    "DD-MM-YYYY"
                  )} ~ ${moment(selectedDates[0].endDate).format("DD-MM-YYYY")}`
                : "Filter by Date"}
              {!(selectedDates[0].startDate && selectedDates[0].endDate) ? (
                <BiCalendar />
              ) : null}
            </div>
            {selectedDates[0].startDate && selectedDates[0].endDate && (
              <button
                onClick={clearDateFilter}
                className="clear-date-filter-button"
              >
                Clear
              </button>
            )}
          </div> */}
        </div>
        {showDatePicker && (
          <div className="custom-date-picker small" ref={datePickerRef}>
            <DateRangePicker
              onChange={handleSelectDate}
              moveRangeOnFirstSelection={false}
              ranges={selectedDates}
              editableDateInputs={true}
              showMonthAndYearPickers={true}
              showDateDisplay={false}
              direction="horizontal"
              className="small"
              // staticRanges={[]} // Remove predefined date ranges
              inputRanges={[]} // Remove input ranges
            />
          </div>
        )}
        <div className="filters-container">
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="formPlantId">
                  <Form.Label style={{ color: "#496c58" }}>Plant</Form.Label>
                  <Select
                    // options={plantList}
                    options={[]}
                    value={
                      plantId
                        ? plantList.find((option) => option.value === plantId)
                        : null
                    }
                    onChange={(option) => {
                      setPlantId(option ? option.value : null);
                      setDeptId(null);
                    }}
                    // isClearable
                    // isSearchable
                    placeholder="Filter by plant"
                    styles={customStyles}
                    isDisabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formDeptId">
                  <Form.Label style={{ color: "#496c58" }}>
                    Department
                  </Form.Label>
                  <Select
                    options={plantId ? deptList : []}
                    value={
                      deptId && plantId
                        ? deptList.find((option) => option.value === deptId)
                        : null
                    }
                    onChange={(option) =>
                      setDeptId(option ? option.value : null)
                    }
                    isClearable
                    isSearchable
                    placeholder="Filter by Department"
                    styles={customStyles}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formCategory">
                  <Form.Label style={{ color: "#496c58" }}>Category</Form.Label>
                  <Select
                    options={categoryList}
                    onChange={(option) =>
                      setCategory(option ? option.value : null)
                    }
                    value={
                      category
                        ? categoryList.find((cat) => cat.value === category)
                        : null
                    }
                    placeholder="Filter by Category"
                    isSearchable
                    isClearable
                    styles={customStyles}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formCategory">
                  <Form.Label style={{ color: "#496c58" }}>Date</Form.Label>
                  {/* <div className="dashboard-date-filter">
                    <div
                      style={{
                        color:
                          selectedDates[0].startDate && selectedDates[0].endDate
                            ? "black"
                            : "grey",
                        // fontSize:"13px"
                        fontWeight: "normal",
                      }}
                      onClick={displayDatePicker}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {selectedDates[0].startDate && selectedDates[0].endDate
                          ? `${moment(selectedDates[0].startDate).format(
                              "DD-MM-YYYY"
                            )} ~ ${moment(selectedDates[0].endDate).format(
                              "DD-MM-YYYY"
                            )}`
                          : "Filter by Date"}
                      </span>
                      {!(
                        selectedDates[0].startDate && selectedDates[0].endDate
                      ) ? (
                        <BiCalendar />
                      ) : null}
                    </div>
                    {selectedDates[0].startDate && selectedDates[0].endDate && (
                      <button
                        onClick={clearDateFilter}
                        className="clear-date-filter-button"
                      >
                        Clear
                      </button>
                    )}
                  </div> */}
                  <div
                    className="dashboard-date-filter"
                    onClick={displayDatePicker}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color:
                          selectedDates[0].startDate && selectedDates[0].endDate
                            ? "black"
                            : "grey",
                        fontWeight: "normal",
                        flex: 1,
                      }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {selectedDates[0].startDate && selectedDates[0].endDate
                          ? `${moment(selectedDates[0].startDate).format(
                              "DD-MM-YYYY"
                            )} ~ ${moment(selectedDates[0].endDate).format(
                              "DD-MM-YYYY"
                            )}`
                          : "Filter by Date"}
                      </span>
                      {/* {!(selectedDates[0].startDate && selectedDates[0].endDate) && (
      <BiCalendar style={{ marginLeft: "8px", marginRight: "0" }} />
    )} */}
                    </div>
                    {selectedDates[0].startDate && selectedDates[0].endDate && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the parent div's onClick
                          clearDateFilter();
                        }}
                        className="clear-date-filter-button"
                        style={{ marginLeft: "8px" }}
                      >
                        Clear
                      </button>
                    )}
                    {!(
                      selectedDates[0].startDate && selectedDates[0].endDate
                    ) && (
                      <BiCalendar
                        style={{ marginLeft: "8px", marginRight: "0" }}
                      />
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        {/* <div className="cards-container">
          {renderFeedbackCount("Total Feedbacks", totalFeedbacks, "black")}
          {renderFeedbackCount("Open", openFeedbacks, "red")}
          {renderFeedbackCount("In Progress", inProgressFeedbacks, "orange")}
          {renderFeedbackCount("Closed", closedFeedbacks, "green")}
        </div> */}
        {/* <div className="feedback-chart-row-1">
          {
            <>
              <div className="chart-container">
                <p className="chart-heading">Status Percentage</p>
                <Chart
                  options={donutChartOptions}
                  series={donutChartSeries}
                  type="donut"
                  height={250}
                />
              </div>
              <div className="chart-container">
                <p className="chart-heading">Feedbacks Per Month</p>
                <Chart
                  options={barChartOptions}
                  series={barChartSeries}
                  type="bar"
                  height={250}
                  // width={400}
                />
              </div>
              <div className="chart-container">
                <p className="chart-heading">Status Number</p>
                <Chart
                  options={statusBarChartOptions}
                  series={statusBarChartSeries}
                  type="bar"
                  height={250}
                  // width={400}
                />
              </div>
            </>
          }
        </div> */}
        <div className="feedback-chart-row-1">
          <div className="chart-container large-chart-1">
            <p className="chart-heading">Suggestions Per Month</p>
            {/* <Chart
              options={barChartOptions}
              series={barChartSeries}
              type="bar"
              height={250}
            /> */}
            <Chart
              options={lineChartOptions}
              series={lineChartSeries}
              type="line"
              height={250}
            />
          </div>
          <div className="chart-container small-chart">
            <p className="chart-heading">
              Total Suggestions
              <br />
              <CountUp
                style={{ fontSize: "25px" }}
                start={0}
                end={totalFeedbacks}
                duration={4}
              />
            </p>
            {/* <div style={{textAlign:"center", fontWeight:"bold"}} >
            <CountUp start={0} end={totalFeedbacks} duration={4} />
            </div> */}
            <Chart
              options={donutChartOptions}
              series={donutChartSeries}
              type="donut"
              height={250}
            />
          </div>
          {/* <div className="chart-container large-chart">
            <p className="chart-heading">Status Number</p>
            <Chart
              options={statusBarChartOptions}
              series={statusBarChartSeries}
              type="bar"
              height={250}
            />
          </div> */}
        </div>

        <div className="feedback-chart-row-1">
          {
            <>
              <div className="chart-container large-chart">
                <p className="chart-heading">Suggestions by Category</p>
                {/* <div style={{textAlign:"center", fontSize:"12px"}}>{"(Number)"}</div> */}
                <Chart
                  options={categoryBarChartOptions}
                  series={categoryBarChartSeries}
                  type="bar"
                  height={250}
                  // width={400}
                />
              </div>
              <div className="chart-container large-chart">
                <p className="chart-heading">Suggestions by Department</p>
                {/* <div style={{textAlign:"center", fontSize:"12px"}}>{"(Number)"}</div> */}
                <Chart
                  options={deptBarChartOptions}
                  series={deptBarChartSeries}
                  type="bar"
                  height={250}
                  // width={400}
                />
              </div>
              {/* <div className="chart-container small-chart">
                <p className="chart-heading">
                  Feedbacks by Department
                </p>
                <div style={{textAlign:"center", fontSize:"12px"}}>{"(Precentage)"}</div>
                <Chart
                  options={deptChartOptions}
                  series={deptChartSeries}
                  type="bar"
                  height={250}
                />
              </div> */}
            </>
          }
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
