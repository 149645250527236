// // // Sidebar.js

// // import React from 'react';
// // import './Sidebar.css'; // Import CSS file for Sidebar styling
// // import { Nav } from 'react-bootstrap';

// // const Sidebar = ({ onSidebarItemClick }) => {
// //   return (
// //     <div className="sidebar">
// //       <Nav className="flex-column">
// //         <Nav.Link onClick={() => onSidebarItemClick('dashboard')}>Dashboard</Nav.Link>
// //         <Nav.Link onClick={() => onSidebarItemClick('feedback')}>Feedback</Nav.Link>
// //       </Nav>
// //     </div>
// //   );
// // };

// // export default Sidebar;

// // Sidebar.js

// import React, { useState } from 'react';
// import './Sidebar.css'; // Import CSS file for Sidebar styling
// import { Nav } from 'react-bootstrap';
// import { BsGrid, BsChat } from 'react-icons/bs'; // React Bootstrap icons
// import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; // Example expand/collapse icons
// import dabur_logo from "../../Assets/Dabur_Logo.svg.png";


// const Sidebar = ({ onSidebarItemClick, currentView }) => {
//   const [collapsed, setCollapsed] = useState(false);

//   const toggleCollapse = () => {
//     setCollapsed(!collapsed);
//   };

//   return (
//     <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
//       <Nav className="flex-column">
//       <div style={{alignSelf:"center"}} className='dabur-logo-sidebar'>
//       <a href='https://www.dabur.com/' target="_blank">
//         <img src={dabur_logo} alt="logo" style={{ height: "90%" }} />
//         </a>
//       </div>
//         <Nav.Link style={{marginTop:"30px"}} className={`sidebar-options ${currentView === 'dashboard' ? 'active' : ''}`} onClick={() => onSidebarItemClick('dashboard')}>
//           <div className="icon-container">
//             {collapsed ? (
//               <BsGrid className="icon" />
//             ) : (
//               <>
//                 <BsGrid className="icon" />
//                 <span className="sidebar-text">Dashboard</span>
//               </>
//             )}
//           </div>
//         </Nav.Link>
//         <Nav.Link className={`sidebar-options ${currentView === 'feedback' ? 'active' : ''}`} onClick={() => onSidebarItemClick('feedback')}>
//           <div className="icon-container">
//             {collapsed ? (
//               <BsChat className="icon" />
//             ) : (
//               <>
//                 <BsChat className="icon" />
//                 <span className="sidebar-text">Suggestions</span>
//               </>
//             )}
//           </div>
//         </Nav.Link>
//       </Nav>

//       {/* <div className="toggle-collapse" onClick={toggleCollapse}>
//         {collapsed ? (
//           <IoIosArrowForward className="icon" />
//         ) : (
//           <IoIosArrowBack className="icon" />
//         )}
//       </div> */}
//     </div>
//   );
// };

// export default Sidebar;


import React, { useState } from 'react';
import './Sidebar.css'; // Import CSS file for Sidebar styling
import { Nav } from 'react-bootstrap';
import { BsGrid, BsChat } from 'react-icons/bs'; // React Bootstrap icons
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; // Example expand/collapse icons
import dabur_logo from "../../Assets/Dabur_Logo.svg.png";


const Sidebar = ({ onSidebarItemClick, currentView, sidebarOpen }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''} ${sidebarOpen ? 'open' : ''}`}>
      <Nav className="flex-column">
        <div style={{alignSelf:"center"}} className='dabur-logo-sidebar'>
          <a href='https://www.dabur.com/' target="_blank">
            <img src={dabur_logo} alt="logo" style={{ height: "90%" }} />
          </a>
        </div>
        <Nav.Link style={{marginTop:"30px"}} className={`sidebar-options ${currentView === 'dashboard' ? 'active' : ''}`} onClick={() => onSidebarItemClick('dashboard')}>
          <div className="icon-container">
            {collapsed ? (
              <BsGrid className="icon" />
            ) : (
              <>
                <BsGrid className="icon" />
                <span className="sidebar-text">Dashboard</span>
              </>
            )}
          </div>
        </Nav.Link>
        <Nav.Link className={`sidebar-options ${currentView === 'feedback' ? 'active' : ''}`} onClick={() => onSidebarItemClick('feedback')}>
          <div className="icon-container">
            {collapsed ? (
              <BsChat className="icon" />
            ) : (
              <>
                <BsChat className="icon" />
                <span className="sidebar-text">Suggestions</span>
              </>
            )}
          </div>
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
