import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";
import "./UserProfileModal.css"; // Import CSS file for UserProfileModal styling
import {
  updateEmployeeDetials,
} from "../../Services/apiServices";
import { getUserDetails } from "../../localStorage";

const UserProfileModal = ({
  show,
  onHide,
  employeeDetails,
  fetchEmpDetials,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { type, token } = getUserDetails();

  useEffect(() => {
    if (employeeDetails[0]) {
      setName(employeeDetails[0].emp_name);
      setEmail(employeeDetails[0].emp_email);
    }
  }, [employeeDetails, show]);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = () => {
    if (!name) {
      alert("Name cannot be empty.");
      return;
    }

    updateEmployeeDetials(name, email, token, type)
      .then((res) => {
        onHide();
        fetchEmpDetials();
      })
      .catch((error) => {
        console.error("Error updating employee details:", error);
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header style={{ background: "#a9cbaa " }} closeButton>
        <Modal.Title className="upm-modal-title">User Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="upm-text-center">
        <PersonCircle size={100} className="upm-mb-3 upm-profile-icon" />
        <Form>
          <Form.Group className="upm-mb-3">
            <Form.Label className="upm-form-label">Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              className="upm-form-control"
              required
              onChange={(e) => handleChange(e)}
              // disabled
            />
          </Form.Group>
          {/* <Form.Group className="upm-mb-3">
            <Form.Label className="upm-form-label">Employee ID</Form.Label>
            <Form.Control
              type="text"
              value={employeeDetails[0].emp_id}
              disabled
              className="upm-form-control"
            />
          </Form.Group> */}
          <Form.Group className="upm-mb-3">
            <Form.Label className="upm-form-label">Mobile</Form.Label>
            <Form.Control
              type="text"
              value={employeeDetails[0].emp_mobile}
              disabled
              className="upm-form-control"
            />
          </Form.Group>
          <Form.Group className="upm-mb-3">
            <Form.Label className="upm-form-label">Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              disabled
              className="upm-form-control"
            />
          </Form.Group>
          <Form.Group className="upm-mb-3">
            <Form.Label className="upm-form-label">Plant Name</Form.Label>
            <Form.Control
              type="email"
              value={employeeDetails[0].plantname}
              disabled
              className="upm-form-control"
            />
          </Form.Group>
          {/* Add more fields as needed */}
        </Form>
      </Modal.Body>
      <Modal.Footer className="upm-modal-footer">
        <Button
          style={{ background: "#004713", width: "200px" }}
          variant="secondary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserProfileModal;
