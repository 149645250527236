import React, { useState, useEffect } from "react";
import "./FeedbackTable.css"; // Import CSS file for FeedbackTable styling
import { Container, Table, Form, Button, Badge } from "react-bootstrap";
import { BiTrash, BiSolidShow, BiCalendar } from "react-icons/bi"; // Import icons for edit and view actions
import {
  getAllFeedbacks,
  getFeedbacksByDept,
  deleteFeedback,
} from "../../Services/apiServices.js";
import FeedbackDetails from "../FeedbackDetails/FeedbackDetials.js";
import { getUserDetails } from "../../localStorage.js";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";

const FeedbackTable = ({ setLoading, employeeDetails }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState(null); // State to store selected feedback for details modal
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState(null);

  const CustomDateInput = ({ value, onClick }) => (
    <div className="custom-date-input" onClick={onClick}>
      {"Date"}
      <BiCalendar size="20px" className="calendar-icon" />
    </div>
  );

  useEffect(() => {
    const userDetials = getUserDetails();
    setUserDetails(userDetials);
  }, []);

  useEffect(() => {
    if (userDetails.token) {
      setLoading(true);
      if (
        userDetails.type === "3" &&
        employeeDetails[0].suggestion_department_head === 0
      ) {
        // Call getAllFeedbacks API
        getAllFeedbacks(
          employeeDetails[0].organization_ref_id,
          userDetails.token,
          userDetails.type
        )
          .then((res) => {
            const fetchedFeedbacks = res.data;
            const uniqueDepartments = Array.from(
              new Set(fetchedFeedbacks.map((feedback) => feedback.department))
            );
            const uniqueCategories = Array.from(
              new Set(fetchedFeedbacks.map((feedback) => feedback.category))
            );

            setFeedbacks(fetchedFeedbacks);
            setDepartmentOptions(uniqueDepartments);
            setCategoryOptions(uniqueCategories);

            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching all feedbacks:", error);
            setLoading(false);
          });
      } else if (
        userDetails.type === "3" &&
        employeeDetails[0].suggestion_department_head === 1
      ) {
        getFeedbacksByDept(
          employeeDetails[0].department_ref_id,
          employeeDetails[0].organization_ref_id,
          employeeDetails[0].plant_ref_id,
          userDetails.token,
          userDetails.type
        )
          .then((res) => {
            const fetchedFeedbacks = res.data;
            const uniqueDepartments = Array.from(
              new Set(fetchedFeedbacks.map((feedback) => feedback.department))
            );
            const uniqueCategories = Array.from(
              new Set(fetchedFeedbacks.map((feedback) => feedback.category))
            );

            setFeedbacks(fetchedFeedbacks);
            setDepartmentOptions(uniqueDepartments);
            setCategoryOptions(uniqueCategories);

            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching feedbacks:", error);
            setLoading(false);
          });
      } else {
        // Call getFeedbacksByDept API
        getFeedbacksByDept(
          employeeDetails[0].department_ref_id,
          employeeDetails[0].organization_ref_id,
          employeeDetails[0].plant_ref_id,
          userDetails.token,
          userDetails.type
        )
          .then((res) => {
            const fetchedFeedbacks = res.data;
            const uniqueDepartments = Array.from(
              new Set(fetchedFeedbacks.map((feedback) => feedback.department))
            );
            const uniqueCategories = Array.from(
              new Set(fetchedFeedbacks.map((feedback) => feedback.category))
            );

            setFeedbacks(fetchedFeedbacks);
            setDepartmentOptions(uniqueDepartments);
            setCategoryOptions(uniqueCategories);

            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching feedbacks:", error);
            setLoading(false);
          });
      }
    }
  }, [userDetails.token, userDetails.type, employeeDetails, setLoading]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    searchQuery,
    selectedDepartment,
    selectedCategory,
    selectedStatus,
    selectedDate,
  ]);

  const handleDeleteClick = (feedback) => {
    setFeedbackToDelete(feedback);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    if (feedbackToDelete) {
      deleteFeedback(feedbackToDelete.id, userDetails.token, userDetails.type)
        .then((res) => {
          refreshFeedbackList();
          setShowModal(false);
          toast.warning("Feedback Deleted Successfully");
        })
        .catch((error) => {
          console.error("Error deleting feedback", error.message);
          setShowModal(false);
        });
    }
  };

  const refreshFeedbackList = () => {
    if (
      userDetails.type === "3" &&
      employeeDetails[0].suggestion_department_head === 0
    ) {
      // Call getAllFeedbacks API
      getAllFeedbacks(
        employeeDetails[0].organization_ref_id,
        userDetails.token,
        userDetails.type
      )
        .then((res) => {
          const fetchedFeedbacks = res.data;
          const uniqueDepartments = Array.from(
            new Set(fetchedFeedbacks.map((feedback) => feedback.department))
          );
          const uniqueCategories = Array.from(
            new Set(fetchedFeedbacks.map((feedback) => feedback.category))
          );

          setFeedbacks(fetchedFeedbacks);
          setDepartmentOptions(uniqueDepartments);
          setCategoryOptions(uniqueCategories);

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching all feedbacks:", error);
          setLoading(false);
        });
    } else if (
      userDetails.type === "3" &&
      employeeDetails[0].suggestion_department_head === 1
    ) {
      getFeedbacksByDept(
        employeeDetails[0].department_ref_id,
        employeeDetails[0].organization_ref_id,
        employeeDetails[0].plant_ref_id,
        userDetails.token,
        userDetails.type
      )
        .then((res) => {
          const fetchedFeedbacks = res.data;
          const uniqueDepartments = Array.from(
            new Set(fetchedFeedbacks.map((feedback) => feedback.department))
          );
          const uniqueCategories = Array.from(
            new Set(fetchedFeedbacks.map((feedback) => feedback.category))
          );

          setFeedbacks(fetchedFeedbacks);
          setDepartmentOptions(uniqueDepartments);
          setCategoryOptions(uniqueCategories);

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching feedbacks:", error);
          setLoading(false);
        });
    } else {
      // Call getFeedbacksByDept API
      getFeedbacksByDept(
        employeeDetails[0].department_ref_id,
        employeeDetails[0].organization_ref_id,
        employeeDetails[0].plant_ref_id,
        userDetails.token,
        userDetails.type
      )
        .then((res) => {
          const fetchedFeedbacks = res.data;
          const uniqueDepartments = Array.from(
            new Set(fetchedFeedbacks.map((feedback) => feedback.department))
          );
          const uniqueCategories = Array.from(
            new Set(fetchedFeedbacks.map((feedback) => feedback.category))
          );

          setFeedbacks(fetchedFeedbacks);
          setDepartmentOptions(uniqueDepartments);
          setCategoryOptions(uniqueCategories);

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching feedbacks:", error);
          setLoading(false);
        });
    }
  };

  const handleViewFeedback = (feedback) => {
    console.log(feedback);
    setSelectedFeedback(feedback);
    setDetailsModalOpen(true);
  };

  const handleDeleteFeedback = (feedback) => {
    console.log("Delete feedback:", feedback.id);
    deleteFeedback(feedback.id, userDetails.token, userDetails.type).then(
      (res) => {
        refreshFeedbackList();
      }
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Filtered and paginated feedbacks
  const filteredFeedbacks = feedbacks.filter((feedback) => {
    console.log(feedback);
    const matchesSearchQuery =
      feedback.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feedback.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feedback.contact.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feedback.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (feedback.brief_idea &&
        feedback.brief_idea
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (feedback.impact_on_business &&
        feedback.impact_on_business
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      feedback.CreatedAt.split("T")[0]
        .split("-")
        .reverse()
        .join("-")
        .includes(searchQuery) ||
      feedback.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feedback.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feedback.department.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesDepartment = selectedDepartment
      ? feedback.department.toLowerCase() === selectedDepartment.toLowerCase()
      : true;

    const matchesCategory = selectedCategory
      ? feedback.category.toLowerCase() === selectedCategory.toLowerCase()
      : true;

    const matchesStatus = selectedStatus
      ? feedback.status.toLowerCase() === selectedStatus.toLowerCase()
      : true;

    const date = selectedDate
      ? moment(selectedDate, "ddd MMM DD YYYY HH:mm:ss ZZ")
          .tz("Asia/Kolkata")
          .add(1, "day")
          .toDate()
          .toISOString()
          .split("T")[0]
      : null;

    const matchesDate = selectedDate
      ? feedback.CreatedAt.split("T")[0] === date
      : true;

    console.log(feedback.CreatedAt.split("T")[0], date);
    console.log(matchesDate);

    return (
      matchesSearchQuery &&
      matchesDepartment &&
      matchesCategory &&
      matchesStatus &&
      matchesDate
    );
  });

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredFeedbacks.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  console.log(currentEntries);

  const totalPages = Math.ceil(filteredFeedbacks.length / entriesPerPage);
  const maxPagesToShow = 3;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClearFilters = () => {
    setSelectedDepartment("");
    setSelectedCategory("");
    setSelectedStatus("");
    setSelectedDate("");
    setSearchQuery("");
  };

  const getStatusBadgeVariant = (status) => {
    switch (status.toLowerCase()) {
      case "open":
        return "danger";
      case "in progress":
        return "warning";
      case "closed":
        return "success";
      default:
        return "secondary";
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (startPage > 1) {
      pageNumbers.push(
        <Button
          className="custom-button"
          key={1}
          variant="outline-primary"
          onClick={() => paginate(1)}
        >
          1
        </Button>
      );
      if (startPage > 2) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      pageNumbers.push(
        <Button
          key={number}
          variant={"outline-primary"}
          onClick={() => paginate(number)}
          style={{
            background: currentPage === number ? "#496c58" : "white",
            color: currentPage === number ? "white" : "#496c58",
          }}
          className="custom-button"
        >
          {number}
        </Button>
      );
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push(<span key="ellipsis2">...</span>);
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <Button
          key={totalPages}
          variant="outline-primary"
          onClick={() => paginate(totalPages)}
          className="custom-button"
        >
          {totalPages}
        </Button>
      );
    }

    return pageNumbers;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#496c58",
      fontWeight: "bold",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#496c58",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
      width: "180px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "120px",
      overflowY: "auto",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#496c58",
      fontWeight: "bold",
    }),
  };

  const statusOptions = [
    { value: "", label: "All Status" },
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];

  const handleChange = (selectedOption, type) => {
    if (type === "department") {
      setSelectedDepartment(selectedOption ? selectedOption.value : "");
    }
    if (type === "category") {
      setSelectedCategory(selectedOption ? selectedOption.value : "");
    }
    if (type === "status") {
      setSelectedStatus(selectedOption ? selectedOption.value : "");
    }
    if (type === "date") {
      setSelectedDate(selectedOption);
      console.log(typeof selectedOption);
    }
  };

  const csvData = feedbacks.map((feedback, index) => ({
    // SrNo: indexOfFirstEntry + index + 1,
    Observation: feedback.message,
    Idea: feedback.brief_idea,
    Impact: feedback.impact_on_business,
    Department: feedback.department,
    Category: feedback.category,
    Name: feedback.name || "N/A",
    Email: feedback.email || "N/A",
    Contact: feedback.contact || "N/A",
    Date: feedback.CreatedAt.split("T")[0].split("-").reverse().join("-"),
    Status: feedback.status,
    "In Progress Comment": feedback.in_progress_comment || "N/A",
    "Closing Comment": feedback.closing_comment || "N/A",
  }));

  return (
    <div className="feedback-table-wrapper">
      <hr style={{ margin: "0px" }} />
      <Container className="feedback-table">
        {/* <div className="header-and-search-container">
          <Form.Group
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
              height: "40px",
            }}
            controlId="search"
          >
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="global-feedback-search"
            />
            {searchQuery ||
            selectedCategory ||
            selectedDepartment ||
            selectedStatus ||
            selectedDate ? (
              <Button
                variant="outline-primary"
                onClick={handleClearFilters}
                className="custom-button"
              >
                Show All
              </Button>
            ) : null}
          </Form.Group>
          <Button
            disabled={feedbacks.length === 0}
            style={{
              borderColor: feedbacks.length === 0 ? "lightgrey" : "#496c58",
              height: "40px",
              color: feedbacks.length === 0 ? "lightgrey" : "#496c58",
            }}
            variant="outline-primary"
            className="custom-button"
          >
            <CSVLink
              data={csvData}
              filename={`suggestions_${
                new Date().toISOString().split("T")[0]
              }.csv`}
              className="custom-button-link"
            >
              Export CSV
            </CSVLink>
          </Button>
        </div> */}
        <div className="header-and-search-container">
          <Form.Group
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
              height: "40px",
              width: "100%",
            }}
            controlId="search"
          >
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="global-feedback-search"
            />
            {searchQuery ||
            selectedCategory ||
            selectedDepartment ||
            selectedStatus ||
            selectedDate ? (
              <Button
                variant="outline-primary"
                onClick={handleClearFilters}
                className="custom-button"
              >
                Show All
              </Button>
            ) : null}
          </Form.Group>
          <Button
            disabled={feedbacks.length === 0}
            style={{
              borderColor: feedbacks.length === 0 ? "lightgrey" : "#496c58",
              height: "40px",
              color: feedbacks.length === 0 ? "lightgrey" : "#496c58",
            }}
            variant="outline-primary"
            className="custom-button"
          >
            <CSVLink
              data={csvData}
              filename={`suggestions_${
                new Date().toISOString().split("T")[0]
              }.csv`}
              className="custom-button-link"
            >
              Export CSV
            </CSVLink>
          </Button>
        </div>

        <hr style={{ marginBottom: 0 }} />
        <Table style={{ minHeight: "20vh" }} bordered responsive>
          <thead style={{ textAlign: "center", fontSize: "13px" }}>
            <tr>
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                }}
              >
                Sr.No
              </th>
              {/* <th>Email</th> */}
              {/* <th>Contact</th> */}
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                  // width: "150px", // Set the desired width
                  // minWidth: "150px", // Ensure the width doesn't go below this value
                  // maxWidth: "150px",
                }}
                // className={"w-10"}
              >
                Observation
              </th>
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                  // width: "150px", // Set the desired width
                  // minWidth: "150px", // Ensure the width doesn't go below this value
                  // maxWidth: "150px",
                }}
                // className={"w-20"}
              >
                Idea
              </th>
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                  // width: "150px", // Set the desired width
                  // minWidth: "150px", // Ensure the width doesn't go below this value
                  // maxWidth: "150px",
                }}
                // className={"w-20"}
              >
                Impact
              </th>
              {/* <th>Audio</th> */}
              <th
                style={{
                  background: "transparent",
                  width: "150px", // Set the desired width
                  minWidth: "150px", // Ensure the width doesn't go below this value
                  maxWidth: "150px",
                }}
              >
                <Select
                  options={departmentOptions.map((dept) => ({
                    value: dept,
                    label: dept,
                  }))}
                  value={
                    selectedDepartment
                      ? { value: selectedDepartment, label: selectedDepartment }
                      : null
                  }
                  onChange={(e) => handleChange(e, "department")}
                  placeholder="Department"
                  styles={customStyles}
                />
              </th>
              <th
                style={{
                  background: "transparent",
                  width: "150px", // Set the desired width
                  minWidth: "150px", // Ensure the width doesn't go below this value
                  maxWidth: "150px",
                }}
              >
                <Select
                  options={categoryOptions.map((cat) => ({
                    value: cat,
                    label: cat,
                  }))}
                  value={
                    selectedCategory
                      ? { value: selectedCategory, label: selectedCategory }
                      : null
                  }
                  onChange={(e) => handleChange(e, "category")}
                  placeholder="Category"
                  styles={customStyles}
                />
              </th>
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                }}
              >
                Name
              </th>
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                  width: "100px", // Set the desired width
                  minWidth: "100px", // Ensure the width doesn't go below this value
                  maxWidth: "100px",
                }}
              >
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => handleChange(date, "date")}
                  customInput={<CustomDateInput />}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Date"
                />
              </th>
              <th
                style={{
                  background: "transparent",
                  width: "100px", // Set the desired width
                  minWidth: "100px", // Ensure the width doesn't go below this value
                  maxWidth: "100px",
                }}
              >
                <Select
                  options={statusOptions}
                  value={
                    selectedStatus
                      ? { value: selectedStatus, label: selectedStatus }
                      : null
                  }
                  onChange={(e) => handleChange(e, "status")}
                  placeholder="Status"
                  styles={customStyles}
                />
              </th>
              <th
                style={{
                  verticalAlign: "middle",
                  color: "#496c58",
                  background: "transparent",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>

          <tbody style={{ textAlign: "center" }}>
            {feedbacks.length === 0 || currentEntries.length === 0 ? (
              <tr>
                <td
                  style={{
                    color: "grey",
                    height: "20vh",
                    verticalAlign: "middle",
                    background: "transparent",
                  }}
                  colSpan="11"
                >
                  No feedbacks
                </td>
              </tr>
            ) : (
              currentEntries.map((feedback, index) => (
                <tr key={index}>
                  <td
                    style={{
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {indexOfFirstEntry + index + 1}
                  </td>
                  {/* <td>{feedback.email ? feedback.email : "N/A"}</td> */}
                  {/* <td>{feedback.contact ? feedback.contact : "N/A"}</td> */}
                  <td
                    style={{
                      textAlign: "left",
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.message}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.brief_idea}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.impact_on_business}
                  </td>
                  {/* <td>
                    {feedback.audio ? (
                      <div>
                        <a
                          href={feedback.audio}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                        <audio controls>
                          <source src={feedback.audio} type="audio/mpeg" />
                          Your browser does not support the audio player.
                        </audio>
                      </div>
                    ) : (
                      <p>No Audio</p>
                    )}
                  </td> */}
                  <td
                    style={{
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.department &&
                      feedback.department
                        .split(" ")
                        .reduce((prev, curr, index) => {
                          if (index % 2 === 0 && index !== 0) {
                            return [...prev, <br key={index} />, curr];
                          } else {
                            return [...prev, " ", curr];
                          }
                        }, [])}
                  </td>{" "}
                  <td
                    style={{
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.category &&
                      feedback.category
                        .split(" ")
                        .reduce((prev, curr, index) => {
                          if (index % 2 === 0 && index !== 0) {
                            return [...prev, <br key={index} />, curr];
                          } else {
                            return [...prev, " ", curr];
                          }
                        }, [])}
                  </td>
                  <td
                    style={{
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.name ? feedback.name : "N/A"}
                  </td>
                  <td
                    style={{
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    {feedback.CreatedAt.split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    <Badge
                      style={{ width: "80px", fontSize: "11px" }}
                      bg={getStatusBadgeVariant(feedback.status)}
                    >
                      {feedback.status}
                    </Badge>
                  </td>
                  <td
                    style={{
                      borderTop: 0,
                      borderBottom: 0,
                      borderRight: "solid",
                      borderColor: "lightgrey",
                      borderWidth: "thin",
                      background: "transparent",
                    }}
                  >
                    <div>
                      <BiSolidShow
                        className="action-icon"
                        onClick={() => handleViewFeedback(feedback)}
                        title="View Feedback"
                      />
                      {/* <BiTrash
                        className="action-icon"
                        onClick={() => handleDeleteClick(feedback)}
                        title="Delete Feedback"
                      /> */}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <div
          className="pagination"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "40px",
          }}
        >
          <div style={{ padding: "10px", fontSize: "11px" }}>
            Powered by{" "}
            <a
              style={{ textDecoration: "none", color: "#000000" }}
              href="https://www.momentumindia.in"
              target="_blank"
            >
              Momentum
            </a>{" "}
            ©
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              className="custom-button"
              variant="outline-primary"
              onClick={() => prevPage()}
            >
              Prev
            </Button>{" "}
            {renderPageNumbers()}
            <Button
              className="custom-button"
              variant="outline-primary"
              onClick={() => nextPage()}
            >
              Next
            </Button>
          </div>
        </div>
        {detailsModalOpen && (
          <FeedbackDetails
            feedback={selectedFeedback}
            isOpen={true}
            onClose={() => setDetailsModalOpen(false)}
            refreshFeedbackList={refreshFeedbackList}
            employeeDetails={employeeDetails}
          />
        )}
        {showModal && (
          <ConfirmationModal
            message={`Are you sure you want to delete this feedback?`}
            show={showModal}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        )}
      </Container>
    </div>
  );
};

export default FeedbackTable;
